<template>
  <div>
    <v-list
      two-line
      subheader
    >
      <v-subheader>MATLAB </v-subheader>

      <v-list-tile avatar>
        <v-list-tile-avatar>
          <v-icon>mdi-code-greater-than</v-icon>
        </v-list-tile-avatar>

        <v-list-tile-content>
          <v-list-tile-title>MATLAB</v-list-tile-title>
          <v-list-tile-sub-title>Execute code to generate plot</v-list-tile-sub-title>
        </v-list-tile-content>

        <v-list-tile-action>
          <v-btn
            icon
            ripple
          >
            <v-icon
              @click="writeCodeToClipboard(matlabCode)"
            >
              mdi-content-copy
            </v-icon>
          </v-btn>
        </v-list-tile-action>
      </v-list-tile>
    </v-list>

    <v-card-text
      v-if="writeRun"
      class="pt-0 mt-0"
    >
      <v-container>
        <v-row>
          <v-col
            cols="12"
          >
            <v-autocomplete
              v-model="selectedItem"
              :disabled="isUpdating"
              :items="items"
              color="blue-grey lighten-2"
              label="Select duty cycle ramp"
              item-text="id"
              item-value="contribution"
            >
              <template #selection="data">
                <span v-if="data.item.current_min !== data.item.current_max && data.item.current_shape === 'dc'">
                  <v-list-tile-title v-html="data.item.temperature_min + '°C ' + data.item.current_shape + ': ' + data.item.current_min + ' - ' + data.item.current_max + ' mA (' + data.item.current_type + ', ' + data.item.current_steps + ' steps)'" />
                </span>
                <span v-else-if="data.item.current_min !== data.item.current_max">
                  <v-list-tile-title v-html="data.item.temperature_min + '°C ' + data.item.current_shape + ': ' + data.item.current_min + ' - ' + data.item.current_max + ' mA, ' + data.item.duty_min + '%, ' + data.item.pulses_min + '/s, (' + data.item.current_type + ', ' + data.item.current_steps + ' steps)'" />
                </span>
                <span v-else-if="data.item.pulses_min !== data.item.pulses_max">
                  <v-list-tile-title v-html="data.item.temperature_min + '°C ' + data.item.current_shape + ': ' + data.item.pulses_min + ' - ' + data.item.pulses_max + '/s, ' + data.item.duty_min + '%, ' + data.item.current_min + ' mA (' + data.item.pulse_type + ', ' + data.item.pulse_steps + ' steps)'" />
                </span>
                <span v-else-if="data.item.duty_min !== data.item.duty_max">
                  <v-list-tile-title v-html="data.item.temperature_min + '°C ' + data.item.current_shape + ': ' + data.item.duty_min + ' - ' + data.item.duty_max + '%, ' + data.item.pulses_min + '/s, ' + data.item.current_min + ' mA (' + data.item.duty_type + ', ' + data.item.duty_steps + ' steps)'" />
                </span>
                <span v-else-if="data.item.current_shape === 'dc'">
                  <v-list-tile-title v-html="data.item.temperature_min + '°C ' + data.item.current_shape + ': ' + data.item.duty_min + ' mA'" />
                </span>
                <span v-else>
                  <v-list-tile-title v-html="data.item.temperature_min + '°C ' + data.item.current_shape + ': ' + data.item.duty_min + '%, ' + data.item.pulses_min + '/s, ' + data.item.current_min + ' mA'" />
                </span>
              </template>
              <template #item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-tile-content v-text="data.item" />
                </template>
                <template v-else>
                  <v-list-tile-content>
                    <span v-if="data.item.current_min !== data.item.current_max && data.item.current_shape === 'dc'">
                      <v-list-tile-title v-html="data.item.temperature_min + '°C ' + data.item.current_shape + ': ' + data.item.current_min + ' - ' + data.item.current_max + ' mA (' + data.item.current_type + ', ' + data.item.current_steps + ' steps)'" />
                    </span>
                    <span v-else-if="data.item.current_min !== data.item.current_max">
                      <v-list-tile-title v-html="data.item.temperature_min + '°C ' + data.item.current_shape + ': ' + data.item.current_min + ' - ' + data.item.current_max + ' mA, ' + data.item.duty_min + '%, ' + data.item.pulses_min + '/s, (' + data.item.current_type + ', ' + data.item.current_steps + ' steps)'" />
                    </span>
                    <span v-else-if="data.item.pulses_min !== data.item.pulses_max">
                      <v-list-tile-title v-html="data.item.temperature_min + '°C ' + data.item.current_shape + ': ' + data.item.pulses_min + ' - ' + data.item.pulses_max + '/s, ' + data.item.duty_min + '%, ' + data.item.current_min + ' mA (' + data.item.pulse_type + ', ' + data.item.pulse_steps + ' steps)'" />
                    </span>
                    <span v-else-if="data.item.duty_min !== data.item.duty_max">
                      <v-list-tile-title v-html="data.item.temperature_min + '°C ' + data.item.current_shape + ': ' + data.item.duty_min + ' - ' + data.item.duty_max + '%, ' + data.item.pulses_min + '/s, ' + data.item.current_min + ' mA (' + data.item.duty_type + ', ' + data.item.duty_steps + ' steps)'" />
                    </span>
                    <span v-else-if="data.item.current_shape === 'dc'">
                      <v-list-tile-title v-html="data.item.temperature_min + '°C ' + data.item.current_shape + ': ' + data.item.duty_min + ' mA'" />
                    </span>
                    <span v-else>
                      <v-list-tile-title v-html="data.item.temperature_min + '°C ' + data.item.current_shape + ': ' + data.item.duty_min + '%, ' + data.item.pulses_min + '/s, ' + data.item.current_min + ' mA'" />
                    </span>
                    <v-list-tile-sub-title
                      v-if="data.item.flag === 'ok'"
                      class="green--text darken-1"
                      v-html="timeStringConv(data.item.timestamp)"
                    />
                    <div v-else>
                      <v-list-tile-sub-title
                        v-if="data.item.comment"
                        class="amber--text darken-1"
                        v-html="timeStringConv(data.item.timestamp) + ': ' + data.item.comment"
                      />
                      <v-list-tile-sub-title
                        v-else
                        class="amber--text darken-1"
                        v-html="timeStringConv(data.item.timestamp)"
                      />
                    </div>
                  </v-list-tile-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
            v-if="selectedObject.spectrafile"
            cols="12"
          >
            <object
              class="gnuplotSVG"
              :data="'https://cmsdb.darkcosmos.org/files/viewplot.php?file='+selectedObject.spectrafile+'&version=svg'+fetchSuffix"
            />
            <div
              v-if="selectedObject.flag === 'ok'"
              class="green--text darken-1"
              v-html="timeStringConv(selectedObject.timestamp)"
            />
            <div v-else>
              <div
                v-if="selectedObject.comment"
                class="amber--text darken-1"
                v-html="timeStringConv(selectedObject.timestamp) + ': ' + selectedObject.comment"
              />
              <div
                v-else
                class="amber--text darken-1"
                v-html="timeStringConv(selectedObject.timestamp)"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
      <pre
        v-if="donecustom && selectedItem !== undefined && selectedItem !== ''"
        v-highlightjs
      ><code class="matlab">{{ matlabCode }}</code></pre>
    </v-card-text>
  </div>
</template>

<script>
import moment from 'moment'
import { EventBus } from '../../scripts/event_bus'

import { mapGetters } from 'vuex'
export default {
  name: 'CorrectedIVP',

  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({})
    },
    led: {
      type: Object,
      required: true,
      default: () => ({})
    },
    custom: {
      type: Array,
      required: true,
      default: () => ([])
    }
  },
  data () {
    return {
      fetchSuffix: '',
      donecustom: false,
      isUpdating: false,
      matlabCode: '',
      writeRun: false,
      items: [],
      spectra: {},
      selectedItem: '',
      params: '',
      selectedObject: {},
      LightPower: {},
      ElYieldAOs: {}
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ])
  },
  watch: {
    data: {
      handler: function (newValue) {
        const self = this
        setTimeout(function () {
          self.selectedItem = self.data.files[0].selection // update value for dropdown based on selection (if given)
        }, 1000)

        this.getSpectra()
        this.updateMatlab()
      },
      deep: true
    },
    custom: {
      handler: function (newValue) {
        const self = this
        setTimeout(function () {
          self.selectedItem = self.data.files[0].selection // update value for dropdown based on selection (if given)
        }, 1000)

        this.updateMatlab()
      },
      deep: true
    },
    selectedItem: function () {
      const self = this
      this.items.forEach(function (item) {
        if (item.contribution === self.selectedItem) {
          self.selectedObject = item
        }
      })
      this.updateMatlab()
    }
  },
  mounted () {
    if (localStorage.getItem('devAPIKey')) {
      this.fetchSuffix = '&apikey=' + localStorage.getItem('devAPIKey')
    }

    //      let gnuplotScript = document.createElement('script')
    //      gnuplotScript.setAttribute('src', 'https://cmsdb.darkcosmos.org/gnuplot.js/addcss.js')
    //      document.head.appendChild(gnuplotScript)
    this.getSpectra()
  },
  methods: {
    writeCodeToClipboard: function (codeToCopy) {
      console.log('Writing', codeToCopy, 'to clipboard')

      window.navigator.clipboard.writeText(codeToCopy).then(() => {
        EventBus.$emit('info', 'Matlab code copied to clipboard successfully')
      }, () => {
        EventBus.$emit('info', 'Copy to clipboard failed... Outdated browser?')
      })
    },
    updateMatlab: function () {
      this.donecustom = false
      this.getPDparams()
      this.getLightPowerAOs()
    },
    getPDparams: function () {
      const self = this
      this.custom.forEach(function (item) {
        if (item.field === 'Photodiode') {
          self.params = item.params
        }
      })
    },
    timeStringConv: function (timestamp) {
      const rightNow = moment()

      return moment.unix(timestamp).calendar(rightNow)
    },
    getLightPowerAOs: function () {
      if (this.selectedObject.id > 0) {
        this.$root.$data.si.getResultAOs([
          { key: 'coll1', value: this.selectedObject.spectra },
          { key: 'coll2', value: this.selectedObject.contribution },
          { key: 'fileid', value: this.selectedObject.id }
        ], (response) => {
          this.LightPower = []
          if (response.aos1) {
            this.LightPower = response.aos1
          }

          this.ElYieldAOs = []
          if (response.aos2) {
            this.ElYieldAOs = response.aos2
          }

          console.log('got', this.LightPower)
          this.writeMatlab()
          this.donecustom = true
        },
        (error) => {
          this.writeMatlab()
          this.donecustom = true
          console.log(error)
        })
      } else {
        this.writeMatlab()
        this.donecustom = true
      }
    },
    getSpectra: function () {
      if (!this.loading) {
        this.loading = true

        this.$root.$data.si.getDuty([
          { key: 'led_id', value: this.led.id },
          { key: 'exp_id', value: this.data.id }

        ], resp => {
          this.spectra = resp
          const self = this

          this.spectra.spectra.forEach(function (element) {
            if (self.data.temperature_min === element.temperature_min && self.data.current_shape === element.current_shape && element.current_type === 'constant' && element.pulse_type === 'constant') {
              self.items.push(element)
            }
          })

          this.loading = false
        })
      }
    },
    writeMatlab: function () {
      this.writeRun = false
      const dbs = []
      const aos = []
      let fileid = 0
      let collid = 0

      if (this.data.files) {
        if (this.data.files[0]) {
          fileid = this.data.files[0].fileid
          collid = this.data.files[0].colluuid
          if (this.data.files[0].aos) {
            this.data.files[0].aos.forEach(function (element) {
              aos.push("'" + element.ao_id + "'")
              dbs.push("'" + element.ltpda + "'")
            })
          }
        }
      }

      const lightpowerdbs = []
      const lightpower = []

      if (this.LightPower[0]) {
        this.LightPower.forEach(function (element) {
          lightpower.push("'" + element.ao_id + "'")
          lightpowerdbs.push("'realpower_duty'")
        })
      }

      const elyielddbs = []
      const elyieldaos = []

      if (this.ElYieldAOs[0]) {
        this.ElYieldAOs.forEach(function (element) {
          elyieldaos.push("'" + element.ao_id + "'")
          elyielddbs.push("'electronyield_duty'")
        })
      }

      console.log('used', this.LightPower)

      let nmmin = 0
      let nmmax = 0

      if (this.led.lotid === '25' && this.led.manid === '13') { // Crystal IS A019C1
        nmmin = 235
        nmmax = 280
      } else if (this.led.lotid === '23' && this.led.manid === '13') { // Crystal IS A017A1
        nmmin = 237
        nmmax = 282
      } else if (this.led.lotid === '27' && this.led.manid === '15') { // SETi TUD59H1B
        nmmin = 245
        nmmax = 290
      } else if (this.led.lotid === '29' && this.led.manid === '15') { // SETi TUD49H1B
        nmmin = 228
        nmmax = 273
      }

      // current settings

      let variable = ''
      let xlabel = ''
      let set = ''

      if (this.data.current_type !== 'constant') {
        if (this.data.current_shape === 'pulse') {
          variable = 'LED drive current set voltage [V]'
          xlabel = 'LED drive current set voltage'
          set = '5'
        } else {
          variable = 'LED drive current [A]'
          xlabel = 'LED drive current'
          set = '5'
        }
      } else if (this.data.pulse_type !== 'constant') {
        variable = 'Pulses per ' + this.data.pulse_frequency + ' seconds'
        xlabel = 'Pulses per ' + this.data.pulse_frequency + ' seconds'
        set = '7'
      } else if (this.data.duty_type !== 'constant') {
        variable = 'Duty cycle [%]'
        xlabel = 'Duty cycle [%]'
        set = '7'
      }

      let coll1 = ''
      let coll2 = ''

      if (this.data.files) {
        if (this.data.files[0].filetypeid === '3') {
          coll1 = 'realpower_ivp'
          coll2 = 'electronyield'
        } else if (this.data.files[0].filetypeid === '13') {
          coll1 = 'realpower_pulses'
          coll2 = 'electronyield_pulses'
        } else if (this.data.files[0].filetypeid === '15') {
          coll1 = 'realpower_duty'
          coll2 = 'electronyield_duty'
        }
      }

      this.matlabCode = 'clear all;\nclose all;\n\n' +
        '% Get IVP data\n' +
          'db = {' + dbs.join(', ') + '};\n' +
          'uuids = {' + aos.join(', ') + '};\n' +
          'ipv = [];\n' +
          'for ii = 1:numel(uuids)\n' +
          'ipv = [ipv ao(plist(\'hostname\', \'localhost\', ...\n' +
          this.data.ltpda +
          '\'database\', db{ii}, ...\n' +
          '\'uuid\', uuids{ii}))];\n' +
          'end\n' +
          'ipv(' + set + ').setXaxisName(\'' + xlabel + '\');\n' +
          'ipv(' + set + ').setYaxisName(\'ADC Voltage\');\n' +
          'ipvIntao = ipv(' + set + ');\n' +
        'x = ipvIntao.data.x; % extract X values\n' +
          'iplot(ipvIntao);\n' +
          'set(gca, \'XScale\', \'log\');\n' +
          'set(gca, \'YScale\', \'log\');\n' +
          '\n' +
          '% Get Duty Cycle Ramps\n' +
          'db = {' + lightpowerdbs.join(', ') + '};\n' +
          'uuids = {' + lightpower.join(', ') + '};\n' +
          'aos = [];\n' +
          'for ii = 1:numel(uuids)\n' +
          'aos = [aos ao(plist(\'hostname\', \'localhost\', ...\n' +
          this.data.ltpda +
          '\'database\', db{ii}, ...\n' +
          '\'uuid\', uuids{ii}))];\n' +
          'end\n' +
        'iplot(aos)\n' +
        'set(gca, \'YScale\', \'log\');\n' +
          '\n' +
        '% Get Duty Cycle Ramps (Electron Yield)\n' +
        'db = {' + elyielddbs.join(', ') + '};\n' +
        'uuids = {' + elyieldaos.join(', ') + '};\n' +
        'aos2 = [];\n' +
        'for ii = 1:numel(uuids)\n' +
        'aos2 = [aos2 ao(plist(\'hostname\', \'localhost\', ...\n' +
        this.data.ltpda +
        '\'database\', db{ii}, ...\n' +
        '\'uuid\', uuids{ii}))];\n' +
        'end\n' +
        'iplot(aos2)\n' +
        'set(gca, \'YScale\', \'log\');\n' +
        '\n' +
        'duty = ' + this.data.duty_min + '; % Constant duty cycle of measurement\n' +
        'pulses = ' + this.selectedObject.pulses_min + '; % Constant number of pulses per second of reference\n' +
        '% Select value in measurement that will be used for scaling (based on reference pulses per second)\n' +
        'scalePoint = interp1(ipvIntao.data.x, [ipvIntao.data.x ipvIntao.data.y], pulses,\'spline\');\n' +
        '% Select value in reference that will be used for scaling (based on measurement duty cycle)\n' +
        'dutyRealPowerPoint = interp1(aos(1).data.x, [aos(1).data.x\' aos(1).data.y\'], duty,\'spline\');\n' +
        'dutyContributionPoint = interp1(aos(1).data.x, [aos(2).data.x\' aos(2).data.y\'], duty,\'spline\');\n' +
        'dutyYield44Point = interp1(aos(1).data.x, [aos2(1).data.x\' aos2(1).data.y\'], duty,\'spline\');\n' +
        'dutyYield52Point = interp1(aos(1).data.x, [aos2(2).data.x\' aos2(2).data.y\'], duty,\'spline\');\n' +
        '% Scale measurement to real power, UV contribution, and\n' +
        'realPower = ipvIntao ./ scalePoint(2) .* dutyRealPowerPoint(2);\n' +
        'realPower.setName(aos(1).name);\n' +
        'realPower.setYunits(aos(1).yunits);\n' +
        'realPower.setYaxisName(aos(1).yaxisname);\n' +
        'contribution = ipvIntao ./ scalePoint(2) .* dutyContributionPoint(2);\n' +
        'contribution.setName(aos(2).name);\n' +
        'contribution.setYunits(aos(2).yunits);\n' +
        'contribution.setYaxisName(aos(2).yaxisname);\n' +
        'yield44 = ipvIntao ./ scalePoint(2) .* dutyYield44Point(2);\n' +
        'yield44.setName(aos2(1).name);\n' +
        'yield44.setYunits(aos2(1).yunits);\n' +
        'yield44.setYaxisName(aos2(1).yaxisname);\n' +
        'yield52 = ipvIntao ./ scalePoint(2) .* dutyYield52Point(2);\n' +
        'yield52.setName(aos2(2).name);\n' +
        'yield52.setYunits(aos2(2).yunits);\n' +
        'yield52.setYaxisName(aos2(2).yaxisname);\n' +
        '\n' +
        '% Plot results\n' +
        'iplot(realPower, contribution);\n' +
        'set(gca, \'XScale\', \'log\');\n' +
        'set(gca, \'YScale\', \'log\');\n' +
        'iplot(yield44, yield52);\n' +
        'set(gca, \'XScale\', \'log\');\n' +
        'set(gca, \'YScale\', \'log\');\n' +
        '\n' +
      '% create data file\n' +
      'fileID = fopen(\'xy.txt\', \'w\');\n' +
      '\n' +
      '%write key labels\n' +
      '    fprintf(fileID, \'"%s" \', \'' + variable + '\');\n' +
        '    fprintf(fileID, \'"%s" \', \'' + this.led.internal_serialnumber + ' (actual light power)\');\n' +
      '    fprintf(fileID, \'"%s" \', \'' + this.led.internal_serialnumber + ': ' + nmmin + '-' + nmmax + ' nm contribution\');\n' +
      '    fprintf(fileID, \'\\n\');\n' +
      '   \n' +
      'for i = 1:numel(x)\n' +
      '    fprintf(fileID, \'%e \', x(i));\n' +
      '    fprintf(fileID, \'%e \', realPower.data.y(i));\n' +
          '    fprintf(fileID, \'%e \', contribution.data.y(i));\n' +
      '    fprintf(fileID, \'\\n\');\n' +
      'end\n' +
      'fclose(fileID);\n' +
      '\n' +
      '% read file content and submit to CMSdb\n' +
      'filetext = fileread(\'xy.txt\');\n' +
      'submit_gnuplot(\'' + fileid + '\', \'' + collid + '\', \'1\', \'' + this.selectedObject.contribution + '\', filetext, \'' + this.userData.api_key + '\')\n' +
      '\n' +
          '% upload real power IVP AOs to CMSdb\n' +
          'realpowerivp_det = submit([realPower contribution], ...\n' +
          'plist(\'hostname\', \'localhost\', ...\n' +
          '\'database\', \'' + coll1 + '\', ...\n' +
          '\'no dialog\', 1, ...\n' +
          this.data.ltpda +
          '\'experiment title\', \'' + this.led.internal_serialnumber + ': Real power IVP ' + this.data.subject + ' (Collection UUID:' + this.data.files[0].colluuid + ')\', ...\n' +
          '\'experiment description\', \'' + this.data.title + ': ' + this.data.description + '\', ...\n' +
          '\'analysis description\', \'Full light power and UV contributions (' + nmmin + '-' + nmmax + ' nm)\', ...\n' +
          '\'additional comments\', \'auto generated by CMSdb\') ...\n' +
          ');\n' +
          'submit_aolist(realpowerivp_det, \'' + this.data.files[0].name + '\', [realPower contribution], \'A1\', \'' + this.data.files[0].fileid + '\', \'' + this.userData.api_key + '\')\n' +
      '\n' +
      '% create data file\n' +
      'fileID = fopen(\'xy2.txt\', \'w\');\n' +
      '\n' +
      '%write key labels\n' +
      '    fprintf(fileID, \'"%s" \', \'' + variable + '\');\n' +
      '    fprintf(fileID, \'"%s" \', \'' + this.led.internal_serialnumber + ' (4.4 eV)\');\n' +
          '    fprintf(fileID, \'"%s" \', \'' + this.led.internal_serialnumber + ' (5.2 eV)\');\n' +
      '    fprintf(fileID, \'\\n\');\n' +
      '   \n' +
      'for i = 1:numel(x)\n' +
      '    fprintf(fileID, \'%e \', x(i));\n' +
      '    fprintf(fileID, \'%e \', yield44.data.y(i));\n' +
      '    fprintf(fileID, \'%e \', yield52.data.y(i));\n' +
      '    fprintf(fileID, \'\\n\');\n' +
      'end\n' +
      'fclose(fileID);\n' +
      '\n' +
      '% read file content and submit to CMSdb\n' +
      'filetext = fileread(\'xy2.txt\');\n' +
      'submit_gnuplot(\'' + fileid + '\', \'' + collid + '\', \'2\', \'' + this.selectedObject.contribution + '\', filetext, \'' + this.userData.api_key + '\')\n' +
      '\n' +
          '% upload electron yield AOs to CMSdb\n' +
          'electronyield_det = submit([yield44 yield52], ...\n' +
          'plist(\'hostname\', \'localhost\', ...\n' +
          '\'database\', \'' + coll2 + '\', ...\n' +
          '\'no dialog\', 1, ...\n' +
          this.data.ltpda +
          '\'experiment title\', \'' + this.led.internal_serialnumber + ': Electron yield ' + this.data.subject + ' (Collection UUID:' + this.data.files[0].colluuid + ')\', ...\n' +
          '\'experiment description\', \'' + this.data.title + ': ' + this.data.description + '\', ...\n' +
          '\'analysis description\', \'4.4 eV and 5.2 eV work functions\', ...\n' +
          '\'additional comments\', \'auto generated by CMSdb\') ...\n' +
          ');\n' +
          'submit_aolist(electronyield_det, \'' + this.data.files[0].name + '\', [yield44 yield52], \'A2\', \'' + this.data.files[0].fileid + '\', \'' + this.userData.api_key + '\')\n'
      this.writeRun = true
    }
  }
}
</script>
<style scoped>
</style>
